//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BienPreview',
  props: {
    bien: Object
  },
  methods: {
    classStatus (value) {
      // "En vente" + "Sous offre" + "Sous compromis" + "Vendu"
      if (value !== '' && value !== undefined) {
        return this.$options.filters.camelCase(value)
      }
      return ''
    }
  }
}
